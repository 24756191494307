<template>
  <div>
    <div class="flex justify-between">
      <div></div>
      <div class="">
        <PageTopNavigation :items="items" item-width="w-32" />
      </div>
      <div>
        <div class="flex">
          <GridViewListView
            class="inline-flex justify-end"
            :grid-path="gridPath"
            :list-path="listPath"
          />
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import PageTopNavigation from "@/components/atom/PageTopNavigation";
import GridViewListView from "@/components/atom/GridViewListView";
import data from "@/data/live-class/data";
export default {
  name: "TabRouter",
  components: {
    GridViewListView,
    PageTopNavigation,
  },
  computed: {
    layout:     vm => vm.$route.meta.layout, // Where from navigate student or teacher
    items:      vm => data.topNavigation[vm.layout],
    gridPath:   vm => `${vm.layout}.liveClass.schedule`,
    listPath:   vm => `${vm.layout}.liveClass.scheduleList`,
  },
};
</script>
